import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import EmptyState from 'components/EmptyState';
import Heatmap from 'components/Heatmap';
import LabeledBox from 'components/LabeledBox';
import CardDrawer from 'features/card/CardDrawer';
import ContributionChart from 'features/dashboard/ContributionChart';
import LeaderboardList from 'features/dashboard/LeaderboardList';
import api from 'utils/api';
import AverageScore from './AverageScore';
import CollectionDetails from './CollectionDetails';
import CollectionStepDistribution from './CollectionStepDistribution';
import CollectionWordcloud from './CollectionWordcloud';
import CollectionReportList from './CollectionReportList';
import { getCollectingPlural } from './collectionUtils';

const breakpointColumnsObj = {
  default: 2,
  992: 2,
  768: 1
};

const CollectionDashboard = ({ collection }) => {
  const {
    isOpen: showCardDrawer,
    onOpen: openCardDrawer,
    onClose: closeCardDrawer
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [word, setWord] = useState(null);
  const { t } = useTranslation();
  const { slug } = useParams();

  const {
    status: popularityStatus,
    data: popularity,
    isSuccess: popularityIsSuccess
  } = useQuery(['popularity', collection.id, null], async () => {
    const params = new URLSearchParams();
    if (collection.id) {
      params.append('collection', collection.id);
    }
    params.append('hive', slug);
    const { data } = await api.get(`/cards/popularity?${params.toString()}`);
    return data;
  });

  const queryClient = useQueryClient();

  const createReportMutation = useMutation(
    async report =>
      await api.post(`/collections/reports?hive__slug=${slug}`, report),
    {
      onSuccess: ({ data: report }) => {
        const reports = queryClient.getQueryData(['reports', collection.id]);
        queryClient.setQueryData(
          ['reports', collection.id],
          [report, ...reports]
        );
      }
    }
  );

  const handleOnCreateReport = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post('services/generate-collection-summary', {
        collection: collection.id
      });
      await createReportMutation.mutateAsync({
        collection: collection.id,
        title: data.title,
        report: data.content
      });
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleOnWordClick = useCallback(
    (_, { text }) => {
      setWord(text);
      openCardDrawer();
    },
    [openCardDrawer]
  );

  const wordcloud = useMemo(
    () => (
      <CollectionWordcloud
        collectionId={collection.id}
        step={-1}
        emptyState={
          <Text textAlign="center" variant="muted">
            {t('common.nothing_here_yet')}
          </Text>
        }
        onWordClick={handleOnWordClick}
      />
    ),
    [collection.id, handleOnWordClick, t]
  );

  return (
    <Stack spacing={8}>
      <Heading fontSize="3xl">{t('common.dashboard')}</Heading>
      <Tabs
        size="sm"
        variant="fresh"
        index={tabIndex}
        onChange={tabIndex => {
          setTabIndex(tabIndex);
        }}
      >
        <TabList>
          <Tab>{t('common.overview')}</Tab>
          <Tab>{t('common.heatmap')}</Tab>
          {collection.workflow && <Tab>{t('common.scoring')}</Tab>}
          <Tooltip
            hasArrow
            label={t('collection.generate_report_explainer')}
            placement="bottom"
          >
            <Tab>
              <HStack>
                <Text>{t('common.reports')}</Text>
                <Text color={useColorModeValue('yellow.500', 'yellow.200')}>
                  <FontAwesomeIcon icon={faSparkles} />
                </Text>
              </HStack>
            </Tab>
          </Tooltip>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Stack spacing={8}>
              <LabeledBox label={t('dashboard.number_of_contributions')}>
                <ContributionChart collectionId={collection.id} />
                {/* <TimeSeriesChart
                  endpoint="/metrics/contributions"
                  queryKey="contributions-metrics"
                  metricOptions={[
                    { id: 'NEW', option: t('dashboard.new_contributions') },
                    { id: 'TOTAL', option: t('dashboard.total_contributions') }
                  ]}
                  filter={{ collection: collection.id }}
                /> */}
              </LabeledBox>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid"
                columnClassName="masonry-column"
              >
                <CollectionDetails collection={collection} />
                <LeaderboardList
                  query="most-contributions"
                  title={t('dashboard.most_contributions')}
                  collectionId={collection.id}
                />
                <LeaderboardList
                  query="most-comments"
                  title={t('dashboard.most_comments')}
                  collectionId={collection.id}
                />
                {collection.workflow && (
                  <CollectionStepDistribution
                    collection={collection}
                    label={t('collection.collected_type_plural', {
                      type_plural: getCollectingPlural(
                        collection.type
                      ).toLowerCase()
                    })}
                  />
                )}
                <LabeledBox label={t('common.wordcloud')}>
                  {wordcloud}
                  <CardDrawer
                    filter={{
                      collection: collection?.id,
                      search: word
                    }}
                    header={word}
                    isOpen={showCardDrawer}
                    onClose={closeCardDrawer}
                  />
                </LabeledBox>
              </Masonry>
            </Stack>
          </TabPanel>
          <TabPanel>
            <LabeledBox label={t('common.heatmap')}>
              <Heatmap
                data={popularity}
                isSuccess={popularityIsSuccess}
                status={popularityStatus}
                emptyState={
                  <EmptyState
                    title={t('common.nothing_here_yet')}
                    filename="tea.svg"
                  />
                }
              />
            </LabeledBox>
          </TabPanel>
          <TabPanel>
            {collection.workflow && (
              <LabeledBox label={t('common.scoring')}>
                <AverageScore
                  collectionId={collection.id}
                  workflowId={collection.workflow.id}
                  stepId={null}
                  active={tabIndex === 2}
                />
              </LabeledBox>
            )}
          </TabPanel>
          <TabPanel>
            <Stack spacing={4}>
              <LabeledBox
                label={t('common.reports')}
                right={
                  <Flex padding={4}>
                    <Tooltip
                      hasArrow
                      label={t('collection.generate_report_explainer')}
                      placement="bottom-start"
                    >
                      <Button
                        colorScheme="teal"
                        onClick={handleOnCreateReport}
                        isLoading={isLoading}
                      >
                        <HStack>
                          <Text>{t('collection.generate_report')}</Text>
                          <FontAwesomeIcon icon={faSparkles} />
                        </HStack>
                      </Button>
                    </Tooltip>
                  </Flex>
                }
              >
                <CollectionReportList collectionId={collection.id} />
              </LabeledBox>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

export default CollectionDashboard;
