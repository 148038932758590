import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import {
  Button,
  Center,
  Flex,
  HStack,
  Input,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from '@emotion/react';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getDay,
  getWeek,
  getYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
  subWeeks,
  subYears
} from 'date-fns';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';

const ContributionsTooltip = ({ active, payload }) => {
  const { t } = useTranslation();
  const bg = useColorModeValue('gray.100', 'gray.600');
  const color = useColorModeValue('teal.500', 'teal.200');
  if (active && payload && payload.length) {
    return (
      <Stack bg={bg} padding={4}>
        <Text fontWeight="bold" noOfLines={1}>
          {`${format(new Date(payload[0].payload.date), 'dd MMM, yyyy')}`}
        </Text>
        <HStack>
          <Text>{t('common.contributions')}:</Text>
          <Text
            fontWeight="bold"
            color={payload[0].payload.count > 0 ? color : null}
          >
            {payload[0].payload.count}
          </Text>
        </HStack>
      </Stack>
    );
  }
  return null;
};

const ContributionChart = ({ collectionId }) => {
  const { t } = useTranslation();

  const FILTERS = [
    { id: false, option: t('dashboard.new_contributions') },
    { id: true, option: t('dashboard.total_contributions') }
  ];
  const TRUNCATIONS = [
    { id: 'day', option: 'Week' },
    { id: 'week', option: 'Month' },
    { id: 'month', option: 'Year' }
  ];

  const { slug } = useParams();
  const [truncate, setTruncate] = useState(TRUNCATIONS[2]);
  const [filter, setFilter] = useState(FILTERS[1]);

  const [start, setStart] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [end, setEnd] = useState(endOfWeek(new Date(), { weekStartsOn: 1 }));

  const {
    data: metrics,
    status,
    isSuccess
  } = useQuery(['metrics', 'contributions', start, end, filter], async () => {
    const params = new URLSearchParams({
      truncate: truncate.id,
      start: format(start, 'yyyy-MM-dd'),
      end: format(end, 'yyyy-MM-dd'),
      slug: slug
    });
    if (filter.id) {
      params.append('total', 'true');
    }
    if (collectionId) {
      params.append('collection', collectionId);
    }
    const { data } = await api.get(
      `/metrics/contributions?${params.toString()}`
    );
    return data;
  });

  const getFirstMonday = date => {
    const dayOfWeek = getDay(date);
    const diff = (1 - dayOfWeek + 7) % 7;
    return addDays(date, diff);
  };

  const getLastMonday = date => {
    const dayOfWeek = getDay(date);
    const diff = (dayOfWeek - 1 + 7) % 7;
    return addDays(date, -diff);
  };

  useEffect(() => {
    const currentDate = new Date();
    if (truncate.id === 'day') {
      setStart(startOfWeek(currentDate, { weekStartsOn: 1 }));
      setEnd(endOfWeek(currentDate, { weekStartsOn: 1 }));
    } else if (truncate.id === 'week') {
      const previousMonth = subMonths(currentDate, 1);
      const firstDayOfMonth = startOfMonth(previousMonth);
      const lastDayOfMonth = endOfMonth(previousMonth);
      setStart(getFirstMonday(firstDayOfMonth));
      setEnd(getLastMonday(lastDayOfMonth));
    } else if (truncate.id === 'month') {
      setStart(startOfYear(currentDate));
      setEnd(endOfYear(currentDate));
    }
  }, [truncate]);

  const handleOnClickPrevious = () => {
    if (truncate.id === 'day') {
      setStart(subWeeks(start, 1));
      setEnd(subWeeks(end, 1));
    } else if (truncate.id === 'week') {
      const previousMonth = subMonths(start, 1);
      const firstDayOfMonth = startOfMonth(previousMonth);
      const lastDayOfMonth = endOfMonth(previousMonth);
      setStart(getFirstMonday(firstDayOfMonth));
      setEnd(getLastMonday(lastDayOfMonth));
    } else if (truncate.id === 'month') {
      setStart(subYears(start, 1));
      setEnd(subYears(end, 1));
    }
  };

  const handleOnClickNext = () => {
    if (truncate.id === 'day') {
      setStart(addWeeks(start, 1));
      setEnd(addWeeks(end, 1));
    } else if (truncate.id === 'week') {
      const nextMonth = addMonths(start, 1);
      const firstDayOfMonth = startOfMonth(nextMonth);
      const lastDayOfMonth = endOfMonth(nextMonth);
      setStart(getFirstMonday(firstDayOfMonth));
      setEnd(getLastMonday(lastDayOfMonth));
    } else if (truncate.id === 'month') {
      setStart(addYears(start, 1));
      setEnd(addYears(end, 1));
    }
  };

  const prettyDate = () => {
    if (truncate.id === 'week') {
      return `${format(addWeeks(start, 1), 'MMM yyyy')}`;
    } else if (truncate.id === 'month') {
      return `${getYear(start)}`;
    }
    return `Week ${getWeek(start, {
      weekStartsOn: 1
    })}, ${format(start, 'yyyy')}`;
  };

  const theme = useTheme();
  const fillColors = [
    useColorModeValue(theme.colors.teal['200'], theme.colors.teal['500'])
  ];
  const strokeColors = [
    useColorModeValue(theme.colors.teal['400'], theme.colors.teal['200'])
  ];
  const gridOpacity = useColorModeValue(1.0, 0.2);

  const tooltipBgColor = useColorModeValue(
    theme.colors.gray['100'],
    theme.colors.gray['600']
  );

  const tooltipTextColor = useColorModeValue(
    theme.colors.teal['500'],
    theme.colors.teal['200']
  );

  const formattedXLabel = (value, _) => {
    if (truncate.id === 'day') {
      return format(new Date(value), 'EEE');
    } else if (truncate.id === 'week') {
      return format(new Date(value), 'dd MMM');
    }
    return format(new Date(value), 'MMM');
  };

  return (
    <Stack spacing={8}>
      <SimpleGrid columns={[1, null, 2]} spacing={4}>
        <HStack>
          <Select
            defaultValue={FILTERS[1]}
            placeholder={t('common.select')}
            options={FILTERS}
            getOptionLabel={option => option.option}
            getOptionValue={option => option?.id}
            onChange={filter => {
              setFilter(filter);
            }}
            useBasicStyles
          />
          <Select
            defaultValue={TRUNCATIONS[2]}
            placeholder={t('common.select')}
            options={TRUNCATIONS}
            getOptionLabel={option => option.option}
            getOptionValue={option => option?.id}
            onChange={truncate => {
              setTruncate(truncate);
            }}
            useBasicStyles
          />
        </HStack>
        <Flex justifyContent={['flex-start', null, 'flex-end']}>
          <HStack>
            <Button variant="outline" onClick={handleOnClickPrevious}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <Input
              value={prettyDate()}
              readOnly
              textAlign="center"
              maxW="160px"
            />
            <Button variant="outline" onClick={handleOnClickNext}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </HStack>
        </Flex>
      </SimpleGrid>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.metrics').toLowerCase()
          })
        ]}
        delay={200}
        indicator={
          <Center>
            <Skeleton height="300px" width="100%" />
          </Center>
        }
      >
        {isSuccess && (
          <ResponsiveContainer width="100%" aspect={3 / 1}>
            <AreaChart width={900} height={300} data={metrics}>
              <CartesianGrid opacity={gridOpacity} strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={formattedXLabel} />
              <YAxis dataKey="count" allowDecimals={false} />
              <Tooltip
                contentStyle={{
                  backgroundColor: tooltipBgColor,
                  border: 'none'
                }}
                itemStyle={{
                  color: tooltipTextColor
                }}
                content={<ContributionsTooltip />}
              />
              <Area
                dataKey="count"
                stroke={strokeColors[0]}
                fill={fillColors[0]}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </LoadingWrapper>
    </Stack>
  );
};

export default ContributionChart;
