import { useDrop } from 'react-dnd';
import {
  Badge,
  Flex,
  HStack,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BadgeIcon from 'components/BadgeIcon';
import BoxContainer from 'components/BoxContainer';
import { getCollectingPlural } from './collectionUtils';
import { useTranslation } from 'react-i18next';

const CollectionStepItem = ({
  badgeIconColorScheme = 'gray',
  count,
  icon,
  selected = false,
  step,
  text,
  type,
  changeCardStep,
  onClick,
  hideCards = false
}) => {
  const { t } = useTranslation();
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'CARD',
      drop: card => {
        const cardStep = card.step ? card.step.id : null;
        if (cardStep !== step) {
          changeCardStep(card, { step });
        }
      },
      collect: monitor => ({
        isOver: !!monitor.isOver()
      })
    }),
    [changeCardStep]
  );

  const dropColor = useColorModeValue('gray.100', 'gray.700');

  const elementId = `step-item-${step}`;
  var element = document.getElementById(elementId);
  if (selected && element) {
    element.scrollIntoView();
  }

  return (
    <BoxContainer
      boxShadow={selected ? 'outline' : null}
      onClick={onClick}
      bg={isOver ? dropColor : null}
      innerRef={drop}
      id={elementId}
    >
      <Stack>
        <Flex alignItems="center" justifyContent="space-between">
          <HStack spacing={4}>
            <BadgeIcon icon={icon} colorScheme={badgeIconColorScheme} />
            <Flex flexDirection="column">
              <Text fontSize="sm" fontWeight="bold">
                {text}
              </Text>
              <HStack>
                <Text
                  fontSize="xs"
                  textTransform="lowercase"
                >{`${count} ${getCollectingPlural(type)}`}</Text>
                {hideCards && (
                  <Badge>
                    {count === 1
                      ? t('common.hidden_one')
                      : t('common.hidden_other')}
                  </Badge>
                )}
              </HStack>
            </Flex>
          </HStack>
          <Text
            opacity={selected ? 0.8 : 0.2}
            color={selected ? 'blue.400' : null}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Text>
        </Flex>
      </Stack>
    </BoxContainer>
  );
};

export default CollectionStepItem;
