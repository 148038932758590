import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Container,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import { hasPlanGreaterThanOrEqual, Plans } from 'features/hive/hiveUtils';
import StepDistributionChartSkeleton from 'features/skeletons/StepDistributionChartSkeleton';
import api from 'utils/api';
import ContributionList from './ContributionList';
import NumberOfContributions from './NumberOfContributions';
import LeaderboardList from './LeaderboardList';
import ProjectStateDistribution from './ProjectStateDistribution';
import WorkflowStats from './WorkflowStats';
import LabeledBox from 'components/LabeledBox';
import MemberChart from './MemberChart';

const breakpointColumnsObj = {
  default: 2,
  992: 2,
  768: 1
};

const MasonryTabPanel = ({ children }) => (
  <TabPanel>
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="masonry-grid"
      columnClassName="masonry-column"
    >
      {children}
    </Masonry>
  </TabPanel>
);

const DashboardPage = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug]);

  const {
    data: workflowStepDistributions,
    isLoading,
    isSuccess
  } = useQuery(['workflow-step-distribution', slug], async () => {
    const { data } = await api.get(
      `/metrics/workflow-step-distribution?slug=${slug}`
    );
    return data;
  });

  return (
    <Container maxW="container.lg" marginY={8}>
      {profile?.is_admin_or_manager ? (
        <Stack spacing={8}>
          <Heading fontSize="3xl">{t('common.dashboard')}</Heading>
          <Tabs size="sm" variant="fresh">
            <TabList>
              <Tab>{t('common.contributors')}</Tab>
              <Tab>{t('common.workflows')}</Tab>
              <Tab>{t('common.general')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack spacing={8}>
                  <LabeledBox label={t('dashboard.number_of_members')}>
                    <MemberChart />
                  </LabeledBox>
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="masonry-grid"
                    columnClassName="masonry-column"
                  >
                    <LeaderboardList
                      query="most-contributions"
                      title={t('dashboard.most_contributions')}
                    />
                    <LeaderboardList
                      query="most-comments"
                      title={t('dashboard.most_comments')}
                    />
                    <ContributionList
                      query="contributions-per-group"
                      title={t('dashboard.contributions_per_group')}
                    />
                  </Masonry>
                </Stack>
              </TabPanel>
              <MasonryTabPanel>
                {isLoading ? (
                  <StepDistributionChartSkeleton />
                ) : isSuccess ? (
                  workflowStepDistributions
                    .filter(
                      workflow =>
                        workflow.metrics.collections > 0 ||
                        workflow.steps.length > 0
                    )
                    .map(workflow => (
                      <WorkflowStats key={workflow.id} workflow={workflow} />
                    ))
                ) : null}
              </MasonryTabPanel>
              <MasonryTabPanel>
                <NumberOfContributions />
                {hasPlanGreaterThanOrEqual(hive?.plan, Plans.Professional) && (
                  <ProjectStateDistribution />
                )}
              </MasonryTabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      ) : (
        <Alert colorScheme="red">{t('common.you_cant_access_this_page')}</Alert>
      )}
    </Container>
  );
};

export default DashboardPage;
